.redactor-statusbar {
    margin: 0;
    padding: 8px 0;
    position: relative;
    overflow: hidden;
    list-style: none;
    box-sizing: border-box;
    border: none;

    & li {
        float: right;
        font-size: 12px;
        color: rgba(0, 0, 0, .5);
        padding: 0 10px;
        line-height: 16px;
        border-right: 1px solid rgba(0, 0, 0, .1);
    }
    & li:first-child {
        border-right-color: transparent;
    }
    & a {
        color: rgba(0, 0, 0, .5);
        text-decoration: underline;
    }
    & a:hover {
        color: #f03c69;
        text-decoration: underline;
    }

    &:empty {
        display: none;
    }
}