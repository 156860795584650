.progress {
    position: fixed;
    right: 6px;
    top: 50%;
    padding-right: 24px;
    transform: translateY(-50%);
    transition: all 0.5s;
    cursor: pointer;

    @include bp(800) {
        display: none;
    }
}

.progress_bar {
    position: relative;
    overflow: hidden;
    height: 40vh;
    width: 10px;
    border: 1px solid;
}

.progress_bar--inner {
    position: absolute;
    right: 2px;
    top: 2px;
    left: 2px;
    transition: height 0.5s;
}

.progress_label {
    position: absolute;
    top: 50%;
    right: 0;
    @include font-size(11);
    text-align: center;
    text-transform: uppercase;
    transform: rotate(90deg) translateY(-50%);
}
