html {
    box-sizing: border-box;
}

*, *::before, *::after {
    box-sizing: inherit;
}

body {
    overflow-x: hidden;

    &.with_modal {
        overflow-y: hidden;
    }
}

img {
    max-width: 100%;
}

button {
    cursor: pointer;
}