.progress_tracker--chart_wrapper {
  height: 500px;
  margin: 20px 0 40px;

  @include bp(1000) {
    height: 440px;
  }

  @include bp(600) {
    height: 360px;
  }
}

.progress_tracker--stat_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  padding: 0 2rem;

  @include bp(1000) {
    flex-wrap: wrap;
  }

  @include bp(600) {
    margin-bottom: 1rem;
    padding: 0 1rem;
  }
}

.progress_tracker--main_stat {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 48%;
  padding: 1rem;
  border: 1px solid;
  border-radius: 4px;

  @include bp(1000) {
    width: 100%;

    &:first-of-type {
      margin-bottom: 1rem;
    }
  }

  p {
    margin: 0;
  }

  &--label {
    font-size: 1.5rem;

    @include bp(600) {
      font-size: 1rem;
    }
  }

  &--value {
    font-size: 2rem;
    font-weight: bold;

    @include bp(600) {
      font-size: 1.5rem;
    }
  }
}

.progress_tracker--other_stat {
  width: 32%;
  border: 1px solid;
  border-radius: 4px;

  @include bp(950) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1rem;
  }

  @include bp(760) {
    display: block;
  }

  &--heading {
    margin: 0;
    padding: 0.75rem 1rem;
    border-bottom: 1px solid;
    font-size: 1.25rem;
    text-align: center;

    @include bp(1100) {
      font-size: 1.125rem;
    }

    @include bp(950) {
      width: 60%;
      border-bottom: 0;
      font-size: 1.5rem;
      text-align: left;
    }

    @include bp(760) {
      width: 100%;
      border-bottom: 1px solid;
      font-size: 1.125rem;
      text-align: center;
    }
  }

  &--columns {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include bp(950) {
      width: 50%;
    }

    @include bp(760) {
      width: 100%
    }
  }

  &--column {
    width: 50%;
    padding: 1rem 0.5rem 0;

    &:last-of-type {
      border-left: 1px solid;
    }

    @include bp(950) {
      &:first-of-type {
        border-left: 1px solid;
      }
    }

    p {
      margin: 0;
      text-align: center;
    }
  }

  &--label {
    font-size: 1.5rem;

    @include bp(1100) {
      font-size: 1.125rem;
    }
  }

  &--value {
    padding: 1.25rem 0;
    font-size: 2rem;
    font-weight: bold;

    @include bp(1100) {
      font-size: 1.5rem;
    }
  }
}

.progress_tracker--settings {
  border-top: 1px solid;
  padding: 1rem 2rem;

  &--heading {
    margin: 0 0 1rem;
  }

  @include bp(700) {
    .clearfix:first-of-type {
      margin-bottom: 1rem;
    }
  }

  @include bp(600) {
    padding: 1rem;
  }
}
