.panel_tabs {
    @include clearfix;
    padding-top: 8px;
}

.panel_tabs--tab {
    position: relative;
    bottom: - 1px;
    z-index: 2;
    float: right;
    padding: 0;
    border: 1px solid;
    @include font-size(12);
    text-align: center;
    line-height: 30px;
    cursor: pointer;

    &.notebook,
    &.contents {
        width: 98px;
    }

    &.summaries {
        width: 104px;
    }

    &.manage {
        width: 80px;
    }

    &.notebook,
    &.contents,
    &.summaries,
    &.manage {

        @include bp(600) {
            width: 25%;
        }
    }

    &.active {
        border: 1px solid;
    }

    &:focus {
        outline: none;
    }
}

.panel_content {
    position: absolute;
    top: 40px;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    overflow-x: hidden;
    border-top: 1px solid;
    border-right: 1px solid;
    transition: all 0.5s;

    @include bp(600) {
        border-right: none;
    }

    .trumbowyg-box,
    .trumbowyg-editor {
        min-height: 90px;
    }

    .trumbowyg-editor {
        padding: 10px;
    }

    .trumbowyg-button-pane {
        min-height: 26px;
        padding: 0 5px;
        line-height: 10px;

        @include bp(600) {
            min-height: 21px;
        }

        .trumbowyg-button-group {

            & + .trumbowyg-button-group::before {
                margin: 0 5px;
                height: 25px;

                @include bp(600) {
                    height: 20px;
                }
            }
        }

        button {
            width: 25px;
            height: 25px;
            padding: 1px 0 !important;

            &.trumbowyg-textual-button {
                line-height: 25px;
            }

            @include bp(600) {
                width: 20px;
                height: 20px;

                &.trumbowyg-textual-button {
                    line-height: 20px;
                }
            }
        }

        .trumbowyg-open-dropdown {

            &.trumbowyg-textual-button {
                padding-left: 10px !important;
                padding-right: 18px !important;
            }
        }
    }

    .trumbowyg-dropdown {

        button {
            height: 25px;
            line-height: 25px;
            padding: 0 10px;
            font-size: 12px;

            svg {
                margin-right: 14px;
            }

            @include bp(600) {
                height: 20px;
                line-height: 20px;
            }
        }
    }
}

.expand_panel {
    position: absolute;
    top: $height-bar;
    right: -12px;
    bottom: 0;
    width: 12px;
    padding: 0;
    transition: right 0.5s;

    &:focus {
        outline: none;
    }

    @include bp(900) {
        display: none;
    }
}

.expand_panel--button {
    position: absolute;
    bottom: 10%;
    left: 0;
    padding: 0 5px 0;
    border: 1px solid;
    border-left: 0;
    line-height: 80px;

    .icon-expand {
        display: inline;
    }

    .icon-shrink {
        display: none;
    }

    &.expanded {

        .icon-expand {
            display: none;
        }

        .icon-shrink {
            display: inline;
        }
    }
}