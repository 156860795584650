body {
    @include font-size();
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-weight: normal;
    line-height: 1;

    &.family-serif {
        .notebook_view--input,
        .notebook_view--textarea,
        .notebook_view--select,
        .notebook_view--stat--control,
        .singleEditor,
        .multiEditor,
        .redactor-styles {
            font-family: "Palatino Linotype", Palatino, Palladio, "URW Palladio L", "Book Antiqua", Baskerville, "Bookman Old Style", "Bitstream Charter", "Nimbus Roman No9 L", Garamond, "Apple Garamond", "ITC Garamond Narrow", "New Century Schoolbook", "Century Schoolbook", "Century Schoolbook L", Georgia, serif;
        }

    }

    &.family-sans-serif {
        .notebook_view--input,
        .notebook_view--textarea,
        .notebook_view--select,
        .notebook_view--stat--control,
        .singleEditor,
        .multiEditor,
        .redactor-styles {
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
        }
    }

    &.family-monospace {
        .notebook_view--input,
        .notebook_view--textarea,
        .notebook_view--select,
        .notebook_view--stat--control,
        .singleEditor,
        .multiEditor,
        .redactor-styles p,
        .redactor-styles li {
            font-family: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace;
        }
    }

    &.size-small {
        .notebook_view--input,
        .notebook_view--textarea,
        .notebook_view--select,
        .notebook_view--stat--control,
        .singleEditor,
        .multiEditor,
        .redactor-styles {
            font-size: 10pt;
        }
    }

    &.size-medium {
        .notebook_view--input,
        .notebook_view--textarea,
        .notebook_view--select,
        .notebook_view--stat--control,
        .singleEditor,
        .multiEditor,
        .redactor-styles {
            font-size: 12pt;
        }
    }

    &.size-large {
        .notebook_view--input,
        .notebook_view--textarea,
        .notebook_view--select,
        .notebook_view--stat--control,
        .singleEditor,
        .multiEditor,
        .redactor-styles {
            font-size: 14pt;
        }
    }

    &.size-xlarge {
        .notebook_view--input,
        .notebook_view--textarea,
        .notebook_view--select,
        .notebook_view--stat--control,
        .singleEditor,
        .multiEditor,
        .redactor-styles {
            font-size: 16pt;
        }
    }


}

h1, h2, h3, h4, h5, h6, p, li {
    cursor: default;
}

h1, h2, h3, h4, h5, h6 {
    margin: 1rem 0 0.5rem;
    font-family: Georgia, Times, Times New Roman, serif;
    font-weight: bold;
}
h1 {
    @include font-size(32);
}
h2 {
    @include font-size(28);
}
h3 {
    @include font-size(24);
}
h4 {
    @include font-size(20);
}
h5 {
    @include font-size(18);
}
h6 {
    @include font-size;
}

p {
    margin: 0 0 0.75rem;

    &:last-of-type {
        margin-bottom: 0;
    }
}
