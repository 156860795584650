.theme-dark {
    $bodyBkgd: $bluegrey9;
    $whiteBkgd: $bluegrey8;
    $greydarkBkgd: $bluegrey5;
    $greyBkgd: $bluegrey2;
    $border: $bluegrey5;
    $borderLight: $bluegrey4;
    $borderDark: $bluegrey7;
    $text: $bluegrey1;
    $textDark: $bluegrey0;
    $textMuted: $bluegrey2;
    $textLight: $bluegrey3;

    // Body tag
    background-color: $bodyBkgd;
    color: $text;

    h1, h2, h3, h4, h5, h6 {
        color: $textDark;
    }

    a {
        @include link-colors($orange, $text);
    }

    button {
        background-color: transparent;
        border-color: transparent;
        color: $text;
    }

    input::-webkit-input-placeholder {
        color: $textMuted;
    }
    input::-moz-placeholder {
        color: $textMuted;
    }
    input:-ms-input-placeholder {
        color: $textMuted;
    }
    input:-moz-placeholder {
        color: $textMuted;
    }

    .message {
        background-color: $blueLight;
        color: $blue;

        &.success {
            background-color: $greenLight;
            color: $green;
        }

        &.error {
            background-color: $redLight;
            color: $red;
        }

        &.warning {
            background-color: $yellowLight;
            color: $yellow;
        }
    }

    // Box
    // ---------------------
    .box {
        background-color: $whiteBkgd;
        border-color: $borderDark;
    }

    .box--filters--button {
        @include link-colors($textMuted, $orange);
        color: $textMuted;

        &.active {
            @include link-colors($text, $orange);
            color: $text;
        }

        &:focus {
            color: $orange;
        }
    }

    .box--header {
        background-color: $bluegrey7;
        border-color: $border;
    }

    .box--title {
        color: $text;

        i, svg {
            color: $textMuted;
        }
    }

    .box--header--button {
        @include link-colors($orange, $white);
        background-color: $white;
        border-color: $orange;
        color: $orange;

        &:hover {
            background-color: $orange;
        }
    }

    .box--footer {
        background-color: $bluegrey7;
        border-color: $border;
    }

    // Buttons
    // ---------------------
    .btn {
        @include link-colors($text, $bluegrey7);
        background-color: $bluegrey7;
        border-color: transparent;
        color: $text;

        &:hover {
            background-color: $bluegrey6;
        }

        &:focus {
            box-shadow: $bluegrey4 0px 0px 0px 3px;
        }

        &.primary {
            @include link-colors($white, $white);
            background-color: $orange;
            color: $white;

            &:hover {
                background-color: $orange3;
            }

            &:focus {
                box-shadow: $orange1 0px 0px 0px 3px;
            }
        }

        &.outline {
            @include link-colors($text, $white);
            background-color: $bluegrey6;
            border: none;
            color: $text;

            &:hover {
                background-color: $orange;
                border: none;
            }

            &:focus {
                box-shadow: $orange1 0px 0px 0px 3px;
            }

            &.primary {
                @include link-colors($white, $white);
                background-color: $orange;
                color: $white;

                &:hover {
                    background-color: $orange3;
                }
            }
        }

        &.text {
            @include link-colors($textMuted, $orange);
            background-color: lighten($bodyBkgd, 3%);
            color: $textMuted;

            &:focus {
                color: $orange3;
            }
        }

        &.link {
            @include link-colors($orange, $orange3);
            background-color: transparent;
            color: $orange;

            &:focus,
            &:hover {
                background-color: transparent;
                box-shadow: none;
                color: $orange3;
            }
        }

        &.floating {
            @include link-colors($text, $white);
            background-color: transparent;
            color: $text;

            &:hover {
                background-color: $orange;
                border-color: $orange;
            }

            &:focus {
                box-shadow: $orange1 0px 0px 0px 3px;
            }
        }
    }

    // Forms
    // ---------------------
    .form_control {
        background-color: $bluegrey7;
        border: none;
        color: $text;

        &:focus {
            color: $white;
            background-color: $bluegrey6;
        }
    }

    .form_help_text {
        color: $textMuted;
    }

    .form_toggle--input {

        &:checked + label {
            background-color: $bluegrey3;
            color: $text;
        }
    }

    .form_toggle--label {
        background-color: $bluegrey7;
        border: none;
        color: $bluegrey6;

        &:hover {
            background-color: $bluegrey5;
            color: $orange;
        }
    }

    .form_options--input {

        &:checked + label {
            background-color: $bluegrey6;
        }
    }

    .form_options--label {
        border: none;
    }

    .form_message {

        &.success {
            border-color: $green;

            .form_message--heading,
            .form_message--text {
                color: $green;
            }
        }

        &.error {
            border-color: $red;

            .form_message--heading,
            .form_message--text {
                color: $red;
            }
        }
    }

    .form_message--heading,
    .form_message--text {
        text-align: center;
    }

    .form_message--heading {
        margin: 0 0 0.5rem;
    }

    // Modal
    // ---------------------
    .modal_overlay {
        background-color: $black1;
    }

    .modal_close {
        color: $white;

        &:hover {
            color: $orange;
        }
    }

    .modal {
        background-color: $whiteBkgd;
    }

    .modal_header {
        background-color: $orange;
        border-color: $border;
    }

    .modal_description {
        color: $white;
    }

    .modal_title {
        color: $white;
    }

    .download_novel {
        border-color: $border;
    }

    .download_novel--heading {
        color: $text;
    }

    .scene_histories--list {
        border-right-color: $borderLight;
    }

    .scene_histories--item {

        &--time {
            color: $textMuted;
        }

        &:hover {
            color: $orange;
        }

        &.current {
            border-left-color: $orange;

            .scene_histories--item--date {
                color: $orange;
            }
        }
    }

    .scene_histories--title {

        &.empty {
            color: $textMuted;
        }
    }

    .scene_histories--content {

        &.empty p {
            color: $textMuted;
        }
    }

    // Editor
    // ---------------------
    .singleEditor {
        background-color: inherit;
        border-color: transparent;
        border: none;
        color: $text;

        &:focus {
            background-color: darken($whiteBkgd, 2%);
        }

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: $textLight;
        }
        &::-moz-placeholder { /* Firefox 19+ */
            color: $textLight;
        }
        &:-ms-input-placeholder { /* IE 10+ */
            color: $textLight;
        }
        &:-moz-placeholder { /* Firefox 18- */
            color: $textLight;
        }
    }

    .multiEditor {
        background-color: inherit;
        border-color: transparent;

        &:focus {
            border:none;
            background-color: darken($whiteBkgd, 2%);
        }

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: $textLight;
        }
        &::-moz-placeholder { /* Firefox 19+ */
            color: $textLight;
        }
        &:-ms-input-placeholder { /* IE 10+ */
            color: $textLight;
        }
        &:-moz-placeholder { /* Firefox 18- */
            color: $textLight;
        }

        .redactor-in {
            border-color: transparent;

            &:focus {
                border-color: $borderLight;
            }
        }
    }

    // Redactor
    // ---------------------
    .redactor-focus,
    .redactor-focus:focus {

        .redactor-in {
            background-color: $whiteBkgd;
            border-color: $borderLight;
        }

        .redactor-styles {
            p, li {
                color: $white;
            }
        }
    }

    .redactor-placeholder:before {
        color: $textLight;
    }

    .redactor-in figcaption[placeholder]:empty:before {
        color: $textLight;
    }

    .redactor-toolbar,
    .redactor-air {
        background-color: $whiteBkgd;
        border-color: $borderLight;
    }

    .redactor-toolbar a,
    .redactor-air a {
        @include link-colors($textLight, $orange);
    }

    .redactor-toolbar a.redactor-button-active {
        background: $textLight;
        color: $whiteBkgd;
    }

    .redactor-toolbar,
    .redactor-air {
        a.redactor-button-disabled {

            &:hover {
                color: $greyBkgd;
                background-color: transparent !important;
            }
        }
    }

    .re-button-tooltip {
        background: $greyBkgd;
        color: $whiteBkgd;

        &:after {
            border-color: none;
            border-bottom-color: $borderDark;
        }
    }

    .redactor-dropdown {
        background-color: $bluegrey6;
        box-shadow: transparentize($bluegrey2, 0.8) 0 2px 3px;
        border-color: $borderLight;

        & a {
            border-color: $borderLight;
        }
        & a {
            color: $text;

            &:hover {
                color: $textDark !important;
                background-color: $orange !important;
            }
            &.redactor-dropdown-item-disabled {
                color: $textLight;
                background: $bluegrey7;
            }
        }
    }

    .redactor-dropdown-selector span:hover {
        background: $bluegrey3;
        color: $bluegrey9;
    }

    .redactor-dropdown-selector span.active {
        background: $bluegrey5;
        color: $text;
    }

    .re-dropdown-box-textcolor span[style],
    .re-dropdown-box-backcolor span[style] {
        border-color: $bluegrey5 !important;
    }

    .redactor-styles {

        // content
        color: $text;

        a,
        a:hover {
            color: $color-link;
        }

        blockquote {
            color: rgba(0, 0, 0, .5);
        }

        time, small, var, code, kbd, mark {
            color: inherit;
        }

        dfn,
        abbr {

            &[title] {
                border-color: rgba(0, 0, 0, .5);
            }
        }

        code {
            background-color: $grey1;
        }

        mark {
            background: none;
        }

        kbd {
            border-color: $grey1;
        }

        pre,
        pre code {
            background-color: $grey1;
            color: $text;
        }

        // tables
        table {

            caption {
                color: $grey5;
            }

            th,
            td {
                border-color: $grey7;
            }

            tfoot th,
            tfoot td {
                color: $grey5;
            }
        }
    }


    // Header
    // ---------------------
    .header,
    .adminHeader {
        background-color: $orange;
    }

    .menus {

        @include bp(600) {
            background-color: $orange;
        }
    }

    .menu_link,
    .mobile_button {
        @include link-colors($white, $white, $white);
        color: $white;

        &:hover {
            background-color: $orange3;
        }
    }

    &#dashboard .dashboard,
    &#account .account,
    &#support .support,
    &#users .users,
    &#activity .activity,
    &#blog .blog,
    &#reports .reports,
    &#communications .communications {
        background-color: $orange3;
    }

    .homeButton {
        border-color: $bodyBkgd;
        background-color: $orange;

        &:hover {
            background-color: $orange3;
        }
    }

    // StatusBar
    // ---------------------
    .statusBar {
        background-color: $orange;
    }

    .statusBar--logo {
        border-color: $bodyBkgd;
        background-color: $orange;
    }

    .statusBar--panelButton {
        color: $white;

        &.opened {
            background-color: $whiteBkgd;
            color: $bluegrey2;

            &:focus,
            &:hover {
                outline: none;
                background-color: $orange3;
                color: $white;
            }
        }

        &:focus,
        &:hover {
            outline: none;
            background-color: $orange3;
        }
    }

    .statusBar--writeButton,
    .statusBar--outlineButton,
    .statusBar--organizeButton {
        color: $white;

        &.current {
            background-color: $orange3;
        }

        &:focus,
        &:hover {
            outline: none;
            background-color: $orange3;
        }
    }

    .statusBar--saveStatus {
        background-color: $white3;

        &.save {
            color: $orange;
        }
        &.saved {
            color: $green;
        }
        &.saving {
            color: $grey4;
        }
        &.error {
            color: $red;
        }
    }

    .statusBar--modeButton {
        color: $white;

        &:focus,
        &:hover {
            outline: none;
            background-color: $orange3;
        }
    }

    .statusBar--menuButton {
        color: $white;

        &:focus,
        &:hover {
            outline: none;
            background-color: $orange3;
        }
    }

    .modeMenu {
        background-color: $orange;
    }

    .modeMenu--button {
        color: $white;

        &:focus,
        &:hover {
            outline: none;
            background-color: $orange3;
            color: $white;
        }

        &.current {
            background-color: $orange3;
        }
    }

    .mainMenu {
        background-color: $orange;
    }

    .mainMenu--button {
        @include link-colors($white, $white);

        &:focus,
        &:hover {
            outline: none;
            background-color: $orange3;
        }
    }

    // Panel
    // ---------------------
    .panel {
        background-color: $whiteBkgd;
        border-right: 4px solid $bodyBkgd;
    }

    .panel_tabs {
        background-color: $bodyBkgd;
        border: none;
    }

    .panel_tabs--tab {
        border: none;
        color: $text;

        &:hover {
            color: $orange;
        }

        &.active {
            background-color: $whiteBkgd;
            border: none;
            color: $text;
        }
    }

    .panel_tabs--tab.summaries {
        width: 100px;
    }

    .panel_content {
        background-color: $whiteBkgd;
        border: none;

        .trumbowyg-button-pane {
            background: $bluegrey7;
            border: none;
        }
    }

    .expand_panel {

        &:focus,
        &:hover {

            .expand_panel--button {
                background-color: $orange;
                border: none;
                color: $white;
            }
        }
    }

    .expand_panel--button {
        background-color: $bluegrey3;
        border: none;
        color: $borderDark;
    }

    // Manage Panel
    // ---------------------

    .novel_goals {
        border-color: $border;
    }

    p.novel_goal--line input {
        border-color: transparent;
        border-bottom-color: $borderLight;
        color: $text;

        &:focus,
        &:hover {
            outline: none;
            border-color: $borderLight;
        }
    }

    .novel_goal--progress {
        border: none;
        background-color: $bluegrey7;
    }

    .novel_goal--progress--inner {
        background-color: $bluegrey4;
    }

    .novel_goal--heading {
        color: $text;
    }


    // Summary Panel
    // ---------------------
    .novel_summary--heading {
        border-color: $border;

        i, svg {
            color: $textMuted;
        }

        &:focus,
        &:hover {

            i, svg {
                color: $orange;
            }
        }
    }

    .novel_summary--text--empty {
        color: $textMuted;
    }

    // Notebook Panel
    // ---------------------
    .notebook_header {
        border: none;
    }

    .notebook_title {
        color: $text;
    }

    .notebook_menu {
        border: none;
    }

    .notebook_menu--button {
        border: none;
        color: $textMuted;

        &:focus,
        &:hover {
            outline: none;
            border: none;
            background-color: $bluegrey5;
            color: $textLight;
        }

        &.active {
            border: none;
            background-color: $bluegrey6;
            color: $text;
        }
    }

    .notebook_content--header {
        background-color: $bluegrey6;
        border: none;
    }

    .notebook_content--title {
        color: $text;
    }

    .notebook_search {
        border-color: $borderLight;
    }

    .notebook_list--header {
        border-color: $border;
    }

    .notebook_list--name {
        color: $text;

        small {
            color: $textMuted;
        }

        &.unnamed {
            color: $textMuted;
        }
    }

    .notebook_list--content {
        border-color: transparent;

        &.open {
            background-color: lighten($whiteBkgd, 2%);
            border: none;
        }
    }

    .notebook_list--section--label {
        border-color: $borderLight;
        color: $textMuted;
    }

    .notebook_list--type,
    .notebook_list--stat {
        border-color: $borderLight;
    }

    .notebook_list--stat--label {
        color: $textMuted;
    }

    .notebook_list--description {
        color: $text;
    }

    .notebook_view--section {
        border-color: $border;
    }

    .notebook_view--select {
        background-color: transparent;
        border-color: transparent;
        border-bottom-color: $borderLight;
        color: $text;

        &:focus {
            outline: none;
            border-color: $borderLight;
        }
    }

    .notebook_view--input,
    .notebook_view--textarea {
        background-color: transparent;
        border-color: transparent;
        color: $text;

        &:focus {
            outline: none;
            border-color: $borderLight;
        }

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: $textLight;
        }
        &::-moz-placeholder { /* Firefox 19+ */
            color: $textLight;
        }
        &:-ms-input-placeholder { /* IE 10+ */
            color: $textLight;
        }
        &:-moz-placeholder { /* Firefox 18- */
            color: $textLight;
        }
    }

    .notebook_view--stat--label {
        color: $textMuted;
    }

    .notebook_view--stat--control {
        background-color: transparent;
        border-color: $borderLight;
        color: $text;
    }

    .notebook_settings--name {
        color: $text;
    }

    .notebook_settings--button {
        border-color: $orange;
        color: $orange;

        &:hover {
            background-color: $orange;
            color: $white;
        }
    }

    .notebook_view--section--toggle {
        border-color: transparent;

        &:hover {
            color: $orange;
            border-color: $orange;
        }
    }

    .name_generator,
    .name_generator--content {
        border: none;
        background-color: lighten($bodyBkgd, 3%);
    }

    .name_generator--button {
        border: none;
        color: $textMuted;

        &:hover {
            border-color: $orange;
            color: $orange;
        }

        &.active {
            border-color: $border;
            color: $textDark;
        }
    }

    .name_generator--name {

        &:hover {
            color: $orange;
        }
    }

    .vue-dropzone  {
        border-color: $borderLight;
        color: $text;

        .dropzone-message {
            color: $textMuted;
        }

        &:hover {
            border-color: $orange;

            .dropzone-message {
                color: $orange;
            }
        }
    }

    .imageUpload-inner {
        border-color: $borderLight;
    }

    .imageUpload-button {
        color: $orange;

        &:hover {
            color: $orange3;
        }
    }

    .imageUpload-loader {
        color: $orange;
    }

    // Contents Panel
    // ---------------------
    .contents--novel_wordcount {
        color: $textMuted;
    }

    .contents_chapter {
        border-color: $border;
    }

    .contents_chapter--order {
        color: $textMuted;
    }

    .contents_chapter--title {
        @include link-colors($text, $orange);

        small {
            color: $textMuted;
        }
    }

    .contents_chapter--delete {
        color: $textMuted;

        &:hover {
            color: $orange;
        }
    }

    .contents_chapter--wordcount {
        color: $textMuted;
    }

    .contents_chapter--footer--button {
        border-color: $border;

        &:focus,
        &:hover {
            outline: none;
            color: $orange;
        }
    }

    .contents_scene--order {
        color: $textMuted;
    }

    .contents_scene--title {
        @include link-colors($text, $orange);

        small {
            color: $textMuted;
        }
    }

    .contents_scene--delete {
        color: $textMuted;

        &:hover {
            color: $orange;
        }
    }

    .contents_chapter--add_chapter,
    .contents_chapter--add_scene {
        border-color: $border;
        color: $textMuted;

        &:hover {
            border-color: $orange;
            color: $orange;
        }
    }

    // Progress Bar
    // ---------------------
    .progress_bar {
        border: none;
        background-color: $bluegrey7;
    }

    .progress_bar--inner {
        background-color: $orange;
    }

    .progress_label {
        color: $textDark;
    }

    // Main
    // ---------------------
    .titlePage {
        background-color: $whiteBkgd;
        border-color: $borderLight;
        box-shadow: 0 0 20px 0 transparentize($grey5, 0.8);
    }


    .summary,
    .chapter {
        background-color: $whiteBkgd;
        border: none;
    }

    // Write Mode
    // ---------------------
    .write_mode {

        .chapter--add_scene {
            border-color: $borderLight;
            color: $textMuted;

            &:focus,
            &:hover {
                border-color: $orange;
                color: $orange;
            }
        }

        .scene_footer {
            border-color: $borderLight;
            border-right-color: transparent;
            border-left-color: transparent;
        }

        .scene_footer--history {

            &:focus,
            &:hover {
                outline: none;
                color: $orange;
            }
        }

        .scene_footer--saved {
            color: $textMuted;
        }

        .scene_footer--delete {
            border-left-color: $borderLight;
            color: $textLight;

            &:focus,
            &:hover {
                outline: none;
                color: $orange;
            }
        }

        .scene_footer--notes_button {

            &:focus,
            &:hover {
                outline: none;
                color: $orange;
            }
        }

        .scene_description {
            border-color: $borderLight;
        }

        .scene_divider {
            color: $textLight;
        }


        .add_chapter {
            border-color: $beige3;
            color: $beige3;

            &:focus,
            &:hover {
                border-color: $orange;
                color: $orange;
            }
        }
    }

    // Outline Mode
    // ---------------------
    .outline_mode {

        .chapter_header,
        .chapter_order,
        .chapter_delete,
        .chapter_description,
        .scene_header,
        .scene_order,
        .scene_delete {
            border-color: $border;
        }

        .chapter_description {
            background-color: $bluegrey7;

            .trumbowyg-button-pane {
                background-color: $bluegrey6;
            }

            .trumbowyg-editor:focus {
                background-color: $whiteBkgd;
            }
        }

        .chapter_order,
        .scene_order {
            color: $textMuted;
        }

        .chapter_delete,
        .scene_delete {

            &:focus,
            &:hover {
                color: $orange;
            }
        }
    }

    // Organize Mode
    // ---------------------
    .organize_mode {

        .chapter-draggable-handle {
            &:hover {
                background-color: $greydarkBkgd;

                .chapter-draggable-button {
                    background-color: $greydarkBkgd;
                    color: $textDark;
                }
            }
        }

        .chapter-draggable-button {
            border-color: $border;
        }

        .chapter-draggable-icon {
            color: $text;
        }

        .chapter-ghost {
            background-color: $greydarkBkgd !important;
        }

        .chapter_title span {
            border-color: $border;
            color: $textMuted;
        }

        .chapter_button {
            background-color: $whiteBkgd;

            &:disabled {
                background-color: transparent;
                border-color: $borderLight;
                color: $textLight;
            }
        }

        .chapter_scenes {
            border-color: $border;
        }

        .scene {
            border-color: $border;
        }

        .scene-draggable-handle {
            &:hover {
                background-color: $greydarkBkgd;
            }
        }

        .scene-draggable-button {
            border-color: $border;
            color: $text;

            &.disabled {
                color: $textLight;
            }
        }

        .scene-ghost {
            background-color: $greydarkBkgd !important;
        }

        .scene_title {
            color: $textDark;

            span {
                border-color: $border;
                color: $textMuted;
            }
        }

        .scene_buttons--relocate {
            background-color: $whiteBkgd;

            &:disabled {
                background-color: transparent;
                border-color: $borderLight;
                color: $textLight;
            }
        }

        .scene-cant-relocate {
            color: $textMuted;
        }
    }

    // Auth Page
    // ---------------------
    .auth--price {
        color: $orange;
    }

    .auth--heading {
        border-color: $border;
        color: $text;
    }

    .auth--line {
        border-color: $border;
    }

    .auth--error_message {
        border-color: $red;
        color: $red;
    }

    .error_box {
        background-color: $redLight;
        border-color: $red;
    }

    .error_box--item {
        color: $red;
    }

    // Dashboard
    // ---------------------

    .dashboard_header--title {
        color: $text;
    }

    .dashboard_header--filter--item {
        color: $textMuted;

        &.active {
            color: $text;
        }
    }

    .novel_box {
        background-color: $whiteBkgd;
        border: none;
        color: $text;
    }
    .novel_box--cover {
        background-color: $bluegrey7;
        border: none;

        &:hover {
            .novel_box--title,
            .novel_box--subtitle,
            .novel_box--author {
                color: $orange;
            }
        }
    }

    .novel_box--title,
    .novel_box--subtitle,
    .novel_box--author {
        color: $text;
    }

    .novel_box--stats {
        background-color: darken($bluegrey7, 3%);
        border: none;
    }

    .novel_box--stat--item {
        color: $textMuted;
    }
    .novel_box--buttons {
        border-color: $border;
    }
    .novel_box--current {
        i, svg {
            color: $white;
        }

        .triangle {
            border-top-color: $bluegrey6;
            border-right-color: transparent;
        }
    }

    .notebook_box {
        background-color: $whiteBkgd;
        border: none;
        color: $text;
    }
    .notebook_box--title {
        color: $text;
    }
    .notebook_box--content {
        background-color: $bluegrey7;
        border: none;
    }
    .notebook_box--stat span {
        border-color: $border;
        color: $textMuted;
    }
    .notebook_box--buttons {
        border-color: $border;
    }


    // Create
    // ---------------------
    .create_section {
        border-color: $borderLight;

        &--heading {
            color: $text;
        }

        &--description {
            color: $textMuted;
        }
    }


    // Account
    // ---------------------
    .account--subheading {
        border-color: $border;
        color: $text;
    }

    .account_info--trial {
        color: $orange;
    }

    .stripe--error_message {
        border-color: $red;
        color: $red;
    }

    .update_form--note {
        color: $textMuted;
    }

    // Sidebar
    // ---------------------
    .sidebar_box {
        background-color: $whiteBkgd;
        border: none;
    }

    .sidebar_box--header {
        border-color: $border;
    }

    // Support
    // ---------------------
    .faq {
        background-color: $bluegrey7;
        border: none;
    }

    .faq--question {

        &:hover {
            color: $orange;
        }
    }

    .faq--answer {
        border: none;
    }

    .progress_tracker {
        &--main_stat {
            border-color: $border;
        }

        &--other_stat {
            border-color: $border;

            &--heading {
                border-color: $border;
            }

            &--column {
                &:first-of-type,
                &:last-of-type {
                    border-color: $border;
                }
            }
        }

        &--settings {
            border-color: $border;
        }
    }
}
