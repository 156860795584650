.modal_overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex-modal;
    overflow: hidden;
    opacity: 0.92;
}

.modal_close {
    position: fixed;
    top: 10px;
    right: 10px;
    font-size: 30px;
    line-height: 1;
}

.modal {
    position: fixed;
    top: 10vh;
    left: 50%;
    z-index: $zindex-modal + 1;
    overflow: hidden;
    opacity: 1;
    height: 80vh;
    width: 90%;
    max-width: 1000px;
    transition: all 0.5s;
    transform: translate(-50%);

    &.large {
        max-width: 1400px;
    }
}

.modal_header {
    position: relative;
    height: 5rem;
    padding: 1rem;
}

.modal_title {
    margin: 0;
    font-size: 2rem;
    line-height: 3rem;
    text-align: center;

    &.with_description {
        margin-top: -0.75rem;
    }
}

.modal_description {
    margin: 0 0 1rem;
    text-align: center;
}

.modal_body {
    position: absolute;
    top: 5rem;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    padding: 1rem;
}
