.singleEditor {
    display: block;
    width: auto;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    border: 1px solid;

    &:focus {
        outline: none;
    }
}

.multiEditor {
    width: 100%;
    border: 1px solid;

    &:focus {
        outline: none;
    }
}

.redactor-in {
    border: 1px solid;
    border-top: none;

    &:focus {
        outline: none;
    }

    p {
        margin: 0 0 0.75rem;
    }

    p, li {
        line-height: 1.75;
        cursor: text;
    }

    ul, ol {
        margin: 0 0 0.75rem;
        padding-left: 2rem;
    }
}
