/*
 * Margin Helpers
 */
.mcenter {
    margin-right: auto;
    margin-left: auto;
}

// 0.5rem
.m05 { margin: 0.5rem; }
.mx05 { margin-right: 0.5rem; margin-left: 0.5rem; }
.my05 { margin-top: 0.5rem; margin-bottom: 0.5rem; }
.mt05 { margin-top: 0.5rem; }
.mr05 { margin-right: 0.5rem; }
.mb05 { margin-bottom: 0.5rem; }
.ml05 { margin-left: 0.5rem; }

// 1rem
.m1 { margin: 1rem; }
.mx1 { margin-right: 1rem; margin-left: 1rem; }
.my1 { margin-top: 1rem; margin-bottom: 1rem; }
.mt1 { margin-top: 1rem; }
.mr1 { margin-right: 1rem; }
.mb1 { margin-bottom: 1rem; }
.ml1 { margin-left: 1rem; }

// 1.5rem
.m15 { margin: 1.5rem; }
.mx15 { margin-right: 1.5rem; margin-left: 1.5rem; }
.my15 { margin-top: 1.5rem; margin-bottom: 1.5rem; }
.mt15 { margin-top: 1.5rem; }
.mr15 { margin-right: 1.5rem; }
.mb15 { margin-bottom: 1.5rem; }
.ml15 { margin-left: 1.5rem; }

// 2rem
.m2 { margin: 2rem; }
.mx2 { margin-right: 2rem; margin-left: 2rem; }
.my2 { margin-top: 2rem; margin-bottom: 2rem; }
.mt2 { margin-top: 2rem; }
.mr2 { margin-right: 2rem; }
.mb2 { margin-bottom: 2rem; }
.ml2 { margin-left: 2rem; }

// 4rem
.m4 { margin: 4rem; }
.mx4 { margin-right: 4rem; margin-left: 4rem; }
.my4 { margin-top: 4rem; margin-bottom: 4rem; }
.mt4 { margin-top: 4rem; }
.mr4 { margin-right: 4rem; }
.mb4 { margin-bottom: 4rem; }
.ml4 { margin-left: 4rem; }

/*
 * Padding Helpers
 */
// 0.5rem
.p05 { padding: 0.5rem; }
.px05 { padding-right: 0.5rem; padding-left: 0.5rem; }
.py05 { padding-top: 0.5rem; padding-bottom: 0.5rem; }
.pt05 { padding-top: 0.5rem; }
.pr05 { padding-right: 0.5rem; }
.pb05 { padding-bottom: 0.5rem; }
.pl05 { padding-left: 0.5rem; }

// 1rem
.p1 { padding: 1rem; }
.px1 { padding-right: 1rem; padding-left: 1rem; }
.py1 { padding-top: 1rem; padding-bottom: 1rem; }
.pt1 { padding-top: 1rem; }
.pr1 { padding-right: 1rem; }
.pb1 { padding-bottom: 1rem; }
.pl1 { padding-left: 1rem; }

// 1.5rem
.p15 { padding: 1.5rem; }
.px15 { padding-right: 1.5rem; padding-left: 1.5rem; }
.py15 { padding-top: 1.5rem; padding-bottom: 1.5rem; }
.pt15 { padding-top: 1.5rem; }
.pr15 { padding-right: 1.5rem; }
.pb15 { padding-bottom: 1.5rem; }
.pl15 { padding-left: 1.5rem; }


/*
 * Typography Helpers
 */
.textcenter { text-align: center; }
.textright { text-align: right;  }

/*
 * Misc Helpers
 */
.clearfix {
    @include clearfix;
}