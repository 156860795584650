.dashboard_header {
    @include clearfix;
    position: relative;
    margin: 1rem 0;
    padding-right: 160px;
    padding-left: 2%;

    @include bp(600) {
        padding-right: 2%;
    }
}

.dashboard_header--title {
    float: left;
    margin: 0;
    line-height: 38px;

    @include bp(600) {
        float: none;
        padding-right: 160px;
    }
}

.dashboard_header--filter {
    float: right;
    margin-left: 1rem;

    @include bp(600) {
        float: none;
        text-align: right;
    }
}

.dashboard_header--filter--item {
    font-size: 12px;
    line-height: 38px;

    &:focus {
        outline: none;
    }
}

.dashboard_header--buttons {
    position: absolute;
    top: 0;
    right: 2%;
}

.novel_boxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
}

.novel_box {
    position: relative;
    //width: 290px;
    width: 21%;
    margin: 0 2% 2rem;
    padding: 36px 8px 10px;
    border: 1px solid;

    @include bp(1600) {
        width: 29.3%;
    }

    @include bp(1040) {
        width: 46%;
    }

    @include bp(600) {
        width: 100%;
    }
 }

.novel_box--wordcount {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    font-size: 0.875rem;
    line-height: 36px;
    text-align: center;
}

.novel_box--cover {
    display: block;
    position: relative;
    padding: 30px 12px 160px;
    border: 1px solid;
    text-align: center;
    text-decoration: none;
}

.novel_box--title {
    margin: 0;
    font-size: 30px;
    line-height: 1.3;
    cursor: pointer;
}

.novel_box--subtitle {
    margin: 14px 0 0;
    font-size: 20px;
    line-height: 1.3;
    cursor: pointer;
}

.novel_box--author {
    position: absolute;
    bottom: 1rem;
    right: 0;
    left: 0;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
}

.novel_box--stats {
    padding: 0 0.5rem;
    border: 1px solid;
    border-top: none;
}

p.novel_box--stats--item {
    margin: 0;
    font-size: 0.875rem;
    line-height: 30px;
    text-align: right;

    span {
        float: left;
        width: 100px;
        margin-right: 1rem;
        text-align: left;
    }
}

.novel_box--buttons {
    padding-top: 10px;
}

.novel_box--button {
    display: inline-block;
    margin-left: 2px;
    padding: 0 10px;
    line-height: 2rem;

    &.primary {
        float: right;
        padding: 0 20px;
    }
}

.novel_box--current {
    position: absolute;
    top: 0;
    left: 0;

    i, svg {
        position: absolute;
        top: 1px;
        left: 1px;
        z-index: 5;
        font-size: 18px;
    }

    .triangle {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 4;
        height: 0;
        width: 0;
        border-top: 2rem solid;
        border-right: 2rem solid;
    }
}

.novel_empty {
    padding-top: 7rem;
    text-align: center;

    &--header {
        margin-bottom: 1rem;
        font-size: 2.5rem;
    }

    &--message {
        font-size: 1.5rem;
    }
}



.notebook_box {
    position: relative;
    margin: 0 2% 2rem;
    padding: 0.5rem;
    border: 1px solid;
}

.notebook_box--title {
    margin: 0;
    padding: 0.5rem 110px 0.5rem 0.5rem;
    font-size: 24px;
    line-height: 30px;

    @include bp(800) {
        padding-right: 0.5rem;
    }
}

.notebook_box--content {
    padding-top: 0.5rem;
    border: 1px solid;
}

p.notebook_box--stat {
    float: left;
    width: 31%;
    margin: 0.5rem 1%;
    padding-right: 0.5rem;
    text-align: right;

    @include bp(700) {
        width: 48%;
    }

    @include bp(400) {
        width: 98%;
    }

    span {
        float: left;
        display: inline-block;
        width: 100px;
        margin-right: 0.5rem;
        padding-right: 0.5rem;
        border-right: 1px solid;
    }
}

.notebook_box--buttons {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 100px;
    text-align: right;

    @include bp(800) {
        position: static;
        width: 100%;
        padding-top: 10px;
        text-align: center;
    }
}

.notebook_box--form {
    display: inline;
}

.notebook_box--button {
    display: inline-block;
    margin-left: 2px;
    padding: 4px 10px;
}
