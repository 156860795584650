@use "sass:math";

// Clearfix
@mixin clearfix() {

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

// Rem output with px fallback
@mixin font-size($sizeValue: 16) {
    font-size: $sizeValue + px;
    font-size: math.div($sizeValue, 16)  + rem;

}

// Link Colors
@mixin link-colors($default: inherit, $hover: inherit, $active: inherit) {
    &:link,
    &:visited {
        color: $default;
    }
    &:active,
    &.active {
        color: $active;
    }
    &:hover {
        color: $hover;
    }
}

// Media Query
@mixin bp($width) {
    @media (max-width: (math.div($width, 16)) + em) {
        @content;
    }
}