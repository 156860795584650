.box {
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    border: 1px solid;

    &.skinny {
        max-width: 900px;
    }
}

.box--header {
    position: relative;
    min-height: 3rem;
    padding: 0.75rem 1rem;
    border-bottom: 1px solid;
}

.box--title {
    margin: 0;

    i, svg {
        width: 1.5rem;
        margin-right: 0.5rem;
        text-align: center;
    }
}

.box--header--buttons {
    position: absolute;
    top: 50%;
    right: 0.75rem;
    margin-top: -1rem;
}

.box--header--button {
    display: inline-block;
    padding: 0 12px;
    border: 1px solid;
    line-height: 2rem;
    text-decoration: none;
}

.box--content {
    padding: 1rem;
}

.box--filters {
    margin: -0.5rem 0 1.5rem;
    text-align: right;
}

.box--filters--button {
    display: inline-block;
    margin: 0 0.25rem;
    @include font-size(14);
    text-decoration: none;

    &:focus {
        outline: none;
    }
}

.box--footer {
    padding: 0.5rem 1rem;
    border-top: 1px solid;
}

.box--footer--text {
    margin: 0;
    line-height: 2;
}