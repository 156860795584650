$margin-sm: 0.5rem;
$margin-md: 1rem;
$margin-lg: 2rem;

$padding-sm: 0.5rem;
$padding-md: 1rem;
$padding-lg: 2rem;

$width-panel: 380px;
$width-panelExpanded: 600px;
$width-sidebar: 360px;

$height-header: 32px;
//$height-statusBar: 30px;
$height-statusBar: 34px;
$height-bar: 40px;
$height-notebookBar: 36px;
$height-novelNotes: 24vh;

$border-radius: 6px;

$zindex-fixed: 4;
$zindex-dropdown: 14;
$zindex-header: 16;
$zindex-modal: 150;