.contents--novel_title {
    margin: 2rem 0 0;
    padding: 0 1rem;
    text-align: center;
}

p.contents--novel_wordcount {
    margin: 0.5rem 0 2rem;
    padding: 0 1rem;
    text-align: center;
}

.contents_chapter {
    width: 90%;
    margin: 1.5rem auto 1rem;
    padding-top: 0.5rem;
    border-top: 1px solid;

    &:first-of-type {
        margin-top: 0.5rem;
    }
}

.contents_chapter--header {
    position: relative;
    padding: 0 2rem;
    border-color: inherit;
}

.contents_chapter--order {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 1.5rem;
    border-color: inherit;
    text-align: center;
    line-height: 2rem;
}

.contents_chapter--title {
    display: block;
    padding: 0.25rem 0;
    @include font-size(18);
    line-height: 1.5rem;
    text-decoration: none;

    small {
        margin-left: 4px;
        font-size: 70%;
        vertical-align: 2px;
    }
}

.contents_chapter--scenes {
    padding-left: 1.5rem;
}

.contents_scene {
    position: relative;
    margin: 0.25rem 0;
    padding: 0 1.5rem 0 2rem;
}

.contents_scene--order {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 2rem;
    @include font-size(14);
    text-align: center;
    line-height: 1.5rem;
}

.contents_scene--title {
    display: block;
    text-decoration: none;
    @include font-size(15);
    line-height: 1.5rem;

    small {
        margin-left: 4px;
        font-size: 70%;
        vertical-align: 1px;
    }
}