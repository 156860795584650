button {
    border: none;
}

.btn {
    display: block;
    padding: 0.5rem 1.25rem;
    border: 1px solid;
    @include font-size;
    text-align: center;
    line-height: 1.25;
    text-decoration: none;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    &.block {
        width: 100%;
    }

    &.small {
        padding: 0.25rem 0.875rem;
        @include font-size(14);
    }

    &.link {
        display: inline-block;
        padding: 0;
        border: none;
        font-size: 80%;
        line-height: inherit;
    }
}