.page {
    padding: 0 130px $height-header;

    @include bp(1160) {
        padding-right: 80px;
        padding-left: 80px;
    }

    @include bp(800) {
        padding-right: 20px;
        padding-left: 20px;
    }

    &.skinny {

        .page_content {
            max-width: 768px;
        }
    }

    &.with_sidebar {

        .page_content {
            padding-right: $width-sidebar + 30px;

            @include bp(1160) {
                padding-right: 0;
            }
        }
    }
}

.page_content {
    position: relative;
    max-width: 1400px;
    margin: 0 auto;
}

.sidebar {
    position: absolute;
    top: 0;
    right: 0;
    width: $width-sidebar;

    @include bp(1160) {
        position: static;
        width: 100%;
    }
}

.sidebar_box {
    margin-bottom: 1.5rem;
    border: 1px solid;
}

.sidebar_box--header {
    padding: 1rem;
    border-bottom: 1px solid;
}

.sidebar_box--heading {
    margin: 0;
    text-align: center;
}

.sidebar_box--text {
    margin-top: 10px;
    font-size: 18px;
    text-align: center;
}

.sidebar_box--content {
    padding: 1rem;
}

.vue_loading {
    padding: 60px 0;
    font-size: 40px;
    text-align: center;
}

.message {
    position: fixed;
    right: 30px;
    bottom: $height-header + 10px;
    padding: 12px 24px;
}

.message_text {
    font-size: 18px;
    color: inherit;

    strong {
        margin-right: 8px;
    }
}

.inactiveMessage {
    position: relative;
    max-width: 1400px;
    margin: 20px auto;
    padding: 1.5rem;
    background-color: $redLight;
    color: $red;
}