.thank_you_message {
  max-width: 700px;
  margin: 7rem auto;

  &--title {
    font-size: 4rem;
    color: $orange;
    text-align: center;
  }

  &--video {
    padding: 0 4rem;

    &--wrapper {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      height: 0;
      margin-bottom: 2rem;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  &--content {
    margin-bottom: 1rem;
    font-size: 1.125rem;
    color: $grey7;
    line-height: 1.5;
  }

  &--line {
    border: 1px solid $grey2;
  }

  &--button {
    display: inline-block;
    margin-top: 2rem;
    @include link-colors($white, $white);
    background-color: $orange;
    font-size: 1.5rem;
    color: $white;

    &:hover {
      background-color: $orange3;
    }

    &:focus {
      box-shadow: $orange1 0px 0px 0px 3px;
    }
  }
}
