.novel {
     //position: fixed;
     //top: 0;
     //left: 0;
     //right: 0;
     //bottom: $height-header;
     //overflow-y: scroll;
     //height: 100%;
    padding-top: 4rem;
    padding-right: 130px;
    padding-left: 130px;
    transition: all 0.5s;

    @include bp(1160) {
        padding-right: 80px;
        padding-left: 80px;
    }

    @include bp(800) {
        padding-right: 20px;
        padding-left: 20px;
    }

    @include bp(600) {
        padding-right: 0;
        padding-left: 0;
    }

    &.with_panel {
        padding-left: $width-panel + $height-bar;

        .novel_header {
            left: $width-panel;
        }

        @include bp(1160) {
            padding-right: 80px;
            padding-left: 80px;

            .novel_header {
                left: 0;
            }
        }

        @include bp(800) {
            padding-right: 20px;
            padding-left: 20px;
        }

        @include bp(600) {
            padding-right: 0;
            padding-left: 0;
        }
    }
}

.panel {
    position: fixed;
    top: 0;
    bottom: $height-statusBar;
    left: - ($width-panelExpanded + 40px);
    z-index: 110;
    width: $width-panel;
    transition: all 0.5s;

    @include bp(1160) {
        box-shadow: 0 0 20px 2px transparentize($grey8, 0.8);
    }

    @include bp(600) {
        width: 100%;
    }

    &.open {
        left: 0;

        @include bp(600) {
            right: 0;
        }
    }

    &.expanded {

        @include bp(600) {
            display: none;
        }

        .panel_content {
            width: $width-panelExpanded;
        }

        .expand_panel {
            right: -($width-panelExpanded - $width-panel + 12px);
        }

        @include bp(1160) {
            width: $width-panelExpanded;

            .panel_content {
                width: 100%;
            }

            .expand_panel {
                right: -12px;
            }
        }
    }
}


.statusBar {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 121;
    height: $height-statusBar;
}

.novel_loading {
    margin-top: 30vh;
    text-align: center;
}

p.novel_loading--text {
    margin-bottom: 3rem;
    @include font-size(73);

    @include bp(900) {
        @include font-size(50);
    }
}

.novel_loading--icon {
    font-size: 120px;

    @include bp(900) {
        font-size: 90px;
    }
}

.novel_header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 40px;
    background-color: $orange;
    transition: all 0.5s;
}

.theme-light .novel_header--button {
    display: inline-block;
    @include link-colors($white, $white);
    padding: 0 10px;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    line-height: 40px;

    &:focus,
    &:hover {
        outline: none;
        background-color: $orange3;
    }

    &.right {
        float: right;
    }
}
