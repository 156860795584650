.novel_summaries {
    padding: 1rem 1rem 2rem;
}

.novel_summary {
    margin-top: 2rem;

    &:first-of-type {
        margin-top: 0;
    }
}

.novel_summary--heading {
    margin-bottom: 1rem;
    padding: 0 0.5rem 0.5rem;
    border-bottom: 1px solid;
    @include font-size(18);
    cursor: pointer;

    i, svg {
        float: right;
    }
}

.novel_summary--text {
    min-height: 100px;
    margin-bottom: 1rem;
    padding: 0 0.5rem;
}

.novel_summary--text--empty {
    margin: 1.5rem 0;
    text-align: center;
}

.novel_summaries--chapters {
    margin-top: 6rem;
}