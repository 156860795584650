.scene_histories--container {
  position: relative;
  min-height: 100%;
  padding-left: 240px;

  @include bp(1000) {
    padding-left: 0;
  }
}

.scene_histories--list {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 240px;
  margin: 0;
  padding: 0.25rem 0;
  border-right: 1px solid;
  list-style: none;
  overflow-y: auto;

  @include bp(1000) {
    display: none;
  }
}

.scene_histories--item {
  margin: 0.5rem 0;
  padding: 0.5rem 1rem;
  border-left: 6px solid transparent;
  cursor: pointer;

  &--date {
    display: block;
    margin-bottom: 4px;
  }

  &--time {
    display: block;
    font-size: 0.875rem;
  }
}

.scene_histories--header {
  display: none;

  @include bp(1000) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 1rem 1rem 0;

    .form_control {
      width: auto;
    }
  }

  @include bp(660) {
    justify-content: center;
    flex-wrap: wrap;

    .form_control {
      width: 100%;
      order: 2;
    }

    .btn {
      margin-bottom: 1rem;
      width: 100%;
    }
  }
}

.scene_histories--body {
  padding: 1rem 2rem;
  overflow-y: auto;
}

.scene_histories--body--header {
  display: flex;
  margin-bottom: 1rem;
  padding: 0.5rem 0;
  justify-content: space-between;
}

.scene-histories--body--copy {

  @include bp(1000) {
    display: none;
  }
}

.scene_histories--title {
  margin: 0;

  &.empty {
    font-weight: normal;
    font-style: italic;
  }
}

.scene_histories--content {

   &.empty p {
     font-style: italic;
   }
}


.scene_histories--getting {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;

  p {
    font-size: 2.5rem;
  }
}

.scene_histories--empty {
  padding: 1rem;

  p {
    font-size: 1.5rem;
  }
}
