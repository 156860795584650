@use "sass:math";

.header {
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
    height: $height-header;
    padding-left: 124px;
}

.menus {

    @include bp(600) {
        display: none;
        position: fixed;
        right: 0;
        bottom: $height-header + 4px;
        z-index: $zindex-dropdown - 1;
        min-width: 138px;
        padding: 0 4px;

        &.opened {
            display: block;
        }
    }
}

.menu {

    &.main {
        float: left;

        @include bp(600) {
            float: none;
        }
    }

    &.secondary {
        float: right;

        @include bp(600) {
            float: none;
            margin-top: 10px;
        }
    }
}

.menu_link {
    display: inline-block;
    padding: 0 0.75rem;
    @include font-size(12);
    font-weight: 500;
    line-height: $height-header;
    text-decoration: none;

    @include bp(600) {
        display: block;
        width: 100%;
        text-align: right;
    }

    &--form {
        display: inline-block;
    }
}

.mobile_button {
    display: none;
    float: right;
    padding: 0 0.75rem;
    @include font-size(12);
    font-weight: bold;
    line-height: $height-header;
    text-decoration: none;
    transition: all 0.5s;

    @include bp(600) {
        display: block;
    }
}

$homeButtonVertPadding: math.div((($height-header + 4px) - 16px), 2);

.homeButton {
    position: absolute;
    bottom: 0;
    left: 4px;
    height: $height-header + 4px;
    width: 120px;
    padding: ($homeButtonVertPadding + 1px) 8px ($homeButtonVertPadding - 1px);
    border: 2px solid;
    border-bottom: none;
    text-align: center;

    img {
        height: 16px;
    }
}