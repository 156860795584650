.novel_info {
    padding: 1rem;
}

.novel_info--title {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 0.5rem;
    @include font-size(28);
    font-weight: 700;
    text-align: center;
}

.novel_info--subtitle {
    width: 90%;
    margin-bottom: 4.5rem;
    padding: 0.5rem;
    @include font-size(18);
    text-align: center;
}

.novel_info--author {
    width: 60%;
    padding: 0.25rem;
    text-align: center;
}

.novel_info--genre {
    width: auto;
    margin: 1.5rem auto 1rem;
    border: none;
    border-bottom: 1px solid;
}

.novel_info--download {
    margin: 3rem auto 1rem;
}

.novel_info--tracker {
    margin: 1rem auto 2rem;
}

.novel_info--form_toggle {
    @include clearfix;
    margin-top: 1rem;

    .form_toggle--question {
        line-height: 1.5rem;
    }

    .form_toggle--label {
        min-width: 40px;
        margin: 0.25rem 0;
        padding: 0 0.5rem;
        font-size: 0.875rem;
        line-height: 1rem;
    }
}

.novel_goals {
    padding: 1rem;
    border-bottom: 1px solid;
}

.novel_goals--heading {
    margin: 0 0 1rem;
    text-align: center;
}

.novel_goal {
    margin-bottom: 2rem;
    padding-bottom: 1rem;
}

.novel_goal--label {
    float: left;
    margin: 0;
    @include font-size(18);
    line-height: 28px;
}

.novel_goal--control {
    float: right;
    width: auto;
    text-align: right;
}

.novel_goal--suggested {
    margin-top: 0.25rem;
    @include font-size(14);
    text-align: right;
    font-style: italic;
}

p.novel_goal--line {
    margin-top: 1.5rem;
    @include font-size(14);
    text-align: right;
}

.novel_goal--progress {
    overflow: hidden;
    width: 100%;
    margin-top: 0.5rem;
    padding: 2px;
    border: 1px solid;
}

.novel_goal--progress--inner {
    display: block;
    height: 5px;
}

.novel_goal--selector {
    margin-bottom: 2rem!important;
}


//.novel_goal {
//    margin-bottom: 2rem;
//    padding-bottom: 1rem;
//}
//
//.novel_goal--heading {
//    margin: 1rem 0;
//}
//
//p.novel_goal--line {
//    margin-bottom: 1rem;
//
//    span {
//        float: right;
//    }
//
//    input {
//        width: 80px;
//        border: 1px solid;
//        text-align: right;
//    }
//}
//
//.novel_goal--progress {
//    clear: both;
//    overflow: hidden;
//    width: 100%;
//    padding: 2px;
//    border: 1px solid;
//}
//
//.novel_goal--progress--inner {
//    display: block;
//    height: 5px;
//}
//
//.novel_goal--selector {
//    margin: 2rem 0;
//}
