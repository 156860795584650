@use "sass:math";

$statusBarLogoVertPadding: math.div((($height-statusBar + 4px) - 16px), 2);

.statusBar--logo {
    position: absolute;
    bottom: 0;
    left: 4px;
    height: $height-statusBar + 4px;
    width: 120px;
    padding: ($statusBarLogoVertPadding + 1px) 8px ($statusBarLogoVertPadding - 1px);
    border: 2px solid;
    border-bottom: none;
    text-align: center;

    img {
        height: 16px;
    }

    @include bp(400) {
        left: 0;
        width: 90px;
        border-left: none;

        img {
            height: 12px;
        }
    }
}


.statusBar--panelButton {
    position: absolute;
    top: 0;
    left: 128px;
    width: 70px;
    font-size: 15px;
    line-height: $height-statusBar;
    text-align: center;

    i,svg {
        margin: 0 4px;
    }

    @include bp(400) {
        left: 98px;
    }
}

//.statusBar--info {
//    position: absolute;
//    top: 0;
//    right: 370px;
//    left: 80px;
//    padding: 6px 10px;
//
//    @include bp(700) {
//        display: none;
//    }
//}
//
//.statusBar--info--text {
//    float: left;
//    margin-right: 8px;
//    padding-left: 8px;
//    border-left: 1px solid;
//    font-size: 14px;
//    line-height: $height-statusBar - 12px;
//
//    @include bp(1160) {
//        display: none;
//    }
//
//    &:first-of-type {
//        padding-left: 0;
//        border: none;
//    }
//}

.statusBar--modeButtons {
    position: absolute;
    top: 0;
    right: 73px;

    @include bp(800) {
        right: $height-statusBar + 12px;
    }
}

.statusBar--writeButton,
.statusBar--outlineButton,
.statusBar--organizeButton {
    font-size: 14px;
    line-height: $height-statusBar;
    text-align: center;

    @include bp(680) {
        display: none;
    }
}

.statusBar--outlineButton {
    width: 90px;
}

.statusBar--writeButton {
    width: 80px;
}

.statusBar--organizeButton {
    width: 100px;
}

.statusBar--modeButton {
    display: none;
    width: 66px;
    font-size: 15px;
    line-height: $height-statusBar;
    text-align: center;

    @include bp(680) {
        display: block;
    }
}

//.statusBar--saveStatus {
//    position: absolute;
//    bottom: 4px;
//    right: 74px;
//    width: 100px;
//    margin: 0;
//    font-weight: bold;
//    line-height: $height-statusBar - 8px;
//    text-align: center;
//
//    &.save {
//        cursor: pointer;
//    }
//
//    i, svg {
//        margin-left: 6px;
//    }
//
//    @include bp(800) {
//        right: $height-statusBar + 14px;
//    }
//
//    @include bp(500) {
//        width: 40px;
//
//        i, svg {
//            margin-left: 0;
//        }
//
//        span {
//            display: none;
//        }
//    }
//}

.statusBar--saveStatus {
    position: fixed;
    bottom: 47px;
    right: 13px;
    overflow: hidden;
    height: 40px;
    width: 40px;
    margin: 0;
    border-radius: 50%;
    font-size: 22px;
    line-height: 40px;
    text-align: center;
    transition: all 0.3s;

    &.save {
        font-size: 26px;
    }
}

.statusBar--menuButton {
    position: absolute;
    top: 0;
    right: 0;
    width: 70px;
    border-left: 2px solid;
    font-size: 14px;
    line-height: $height-statusBar;
    text-align: center;

    i,svg {
        display: none;
    }

    @include bp(800) {
        width: $height-statusBar;

        span {
            display: none;
        }

        i, svg {
            display: inline;
        }
    }
}



.modeMenu,
.mainMenu {
    position: fixed;
    bottom: $height-statusBar + 4px;
    z-index: $zindex-dropdown + 130;
    min-width: 138px;
    padding: 0 4px;
}

.modeMenu {
    display: none;

    @include bp(800) {
        display: block;
        right: $height-statusBar + 12px;
    }
}

.mainMenu {
    right: 0;
}

.modeMenu--button,
.mainMenu--button {
    display: block;
    position: relative;
    width: 100%;
    margin: 4px 0;
    padding: 8px 10px;
    font-size: 14px;
    text-align: right;
    cursor: pointer;
    text-decoration: none;
}