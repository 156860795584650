.notebook_menu {
    position: absolute;
    top: 10px;
    right: 0;
    left: 0;
    height: $height-notebookBar;
}

.notebook_menu--button {
    float: left;
    display: block;
    height: $height-notebookBar;
    width: 14.285714%;
    border-bottom: 1px solid;
    font-size: 120%;
    cursor: pointer;

    &.active {
        border: 1px solid;
        border-bottom: 0;

        &:first-of-type {
            border-left: 0;
        }

        &:last-of-type {
            border-right: 0;
        }
    }
}

.notebook_content {
    position: absolute;
    top: $height-notebookBar + $height-bar + 10px + 1px;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    padding: 1rem;
}

.notebook_search {
    width: 100%;
    padding: 4px 8px;
    border: 1px solid
}

// Content Header
.notebook_content--header {
    position: absolute;
    top: $height-notebookBar + 10px;
    right:0;
    left: 0;
    padding: 0 1rem;
    border-bottom: 1px solid;
}

.notebook_content--title {
    margin: 0;
    line-height: $height-bar;
}

.notebook_content--view {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
}

.notebook_content--add {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
}

.notebook_content--inner {
    padding: 0 0.5rem;
}


// List
.notebook_list--item {
    position: relative;
    margin: 1rem 0 2rem;
}

.notebook_list--header {
    padding: 0 50px 0 0.5rem;
    border-bottom: 1px solid;
}

.notebook_list--name {
    margin: 0;
    line-height: 30px;

    small {
        font-weight: normal;
    }
}

.notebook_list--edit {
    display: block;
    position: absolute;
    top: 4px;
    right: 0;
    padding: 0 0.25rem 0 0.5rem!important;
    line-height: 20px;
}

.notebook_list--content {
    position: relative;
    padding: 0.5rem;
    border: 1px solid;
    border-top: none;
}

.notebook_list--icon {
    position: absolute;
    top: 4px;
    right: 0.5rem;
}

.notebook_list--description {
    padding-right: 20px;
}

.notebook_list--type {
    width: 190px;
    margin: 2rem auto 0.5rem;
    padding-bottom: 0.25rem;
    border-bottom: 1px solid;
    @include font-size(18);
    text-align: center;
}

.notebook_list--gender {
    text-align: center;
}

.notebook_list--section {
    margin: 1rem 0;
}

p.notebook_list--section--label {
    margin-bottom: 0.5rem;
    padding-bottom: 0.25rem;
    border-bottom: 1px solid;
    @include font-size(14);
}

.notebook_list--section--content {
    padding: 0 0.5rem;
    cursor: default;

    p, li {
        line-height: 1.25;
    }

    ul, ol {
        margin: -0.25rem 0 0.75rem;
        padding-left: 2rem;
    }
}

.notebook_list--stats {
    margin: 1rem 0 3rem;
}

.notebook_list--stat {
    @include clearfix;
    margin: 1rem 0;
    padding-bottom: 0.25rem;
    border-bottom: 1px solid;
}

p.notebook_list--stat--label {
    float: left;
    margin: 0;
    @include font-size(14);
    line-height: 24px;
}

p.notebook_list--stat--content {
    float: right;
    margin: 0;
    line-height: 24px;
}

.notebook_list--empty {
    width: 90%;
    margin: 2rem auto 1rem;
    text-align: center;
    line-height: 1.5;
}


// View
.notebook_view--name {
    min-width: 260px;
    margin-bottom: 0.75rem;
    padding-top: 0.75rem;
    padding-bottom: 0.5rem;
    @include font-size(24);
    font-weight: 700;
    text-align: center;
}

p.notebook_view--info {
    margin-bottom: 2rem;
    @include font-size(12);
    text-align: center;
    letter-spacing: 1px;

    span {
        margin: 0 8px;
        border-left: 1px solid;
    }
}

.notebook_view--section {
    position: relative;
    margin-top: 1rem;
    padding: 0.5rem 0.5rem 0;
    border-top: 1px solid;
}

p.notebook_view--section--label {
    margin-bottom: 0.5rem;
    @include font-size(13);
}

.notebook_view--section--button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    font-size: 12px!important;
}

.notebook_view--section--toggle {
    position: absolute;
    top: 0.25rem;
    right: 0;
    padding: 0.25rem 0.5rem;
    border: 1px solid;
    font-size: 14px!important;

    &:hover {
    }

    .icon_up {
        display: none;
    }

    .icon_down {
        display: block;
    }

    &.open {

        .icon_up {
            display: block;
        }

        .icon_down {
            display: none;
        }
    }
}

.notebook_view--input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid;
}

.notebook_view--textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid;
    resize: none;
}

.notebook_view--select {
    display: block;
    width: auto;
    margin: 1rem auto;
    padding: 0.25rem 0.5rem;
    border: 1px solid;
    line-height: 1;
}


.notebook_view--stat {
    @include clearfix;
    padding: 0.5rem 0;
}

.notebook_view--stat--label {
    float: left;
    width: 35%;
    padding: 0;
    line-height: 27px;
}

.notebook_view--stat--control {
    float: right;
    width: 60%;
    padding: 0.25rem 0.5rem;
    border: none;
    border-bottom: 1px solid;
    text-align: right;
}


// Settings
.notebook_settings--name {
    margin: 1rem auto 2rem;
    padding: 0.5rem 0;
    @include font-size(28);
    font-weight: 700;
    text-align: center;
}

.notebook_settings--download {
    margin: 0 auto;
}

.notebook_settings--button {
    display: block;
    width: 90%;
    margin: 1rem auto;
    padding: 10px;
    border: 1px solid;
    text-align: center;

    &.new {
        width: 100%;
        margin-top: 2rem;
    }
}


// Name Generator
.name_generator {
    width: 90%;
    margin: 0 auto 1rem;
    border: 1px solid;
}

.name_generator--toggle {
    width: 100%;
    padding: 8px 0;
    text-align: left;

    i, svg {
        float: right;
    }
}

.name_generator--content {
    padding: 10px;
    border-top: 1px solid;
}

.name_generator--buttons {
    @include clearfix;
}

.name_generator--button {
    float: left;
    width: 29%;
    margin: 0 2%;
    padding: 4px;
    border: 1px solid;
}

.name_generator--results {
    @include clearfix;
    margin: 10px 0 0;
    padding: 0;
    list-style: none;
}

.name_generator--name {
    float: left;
    width: 33%;
    margin: 8px 0;
    padding: 0 4px;
    @include font-size(14);
    text-align: center;
    cursor: pointer;
}
