%writeModeBox {
    width: 96%;
    max-width: 1400px;
    margin-right: auto;
    margin-left: auto;
    border: 1px solid;

    @include bp(600) {
        width: 100%;
        border-right: none;
        border-left: none;
    }
}

// Title Page
// ---------------------
.titlePage {
    @extend %writeModeBox;
    margin-bottom: 3rem;
    padding: 3rem 1.5rem;

    @include bp(600) {
        padding: 2rem 0.75rem;
    }
}

.titlePage--title,
.titlePage--subtitle,
.titlePage--author,
.titlePage--wordcount {
    text-align: center;
}

.titlePage--title {
    @include font-size(48);
    font-weight: 700;
}

.titlePage--subtitle {
    margin: 1.5rem auto 5rem;
    @include font-size(40);
    font-weight: 700;
}

.titlePage--author {
    @include font-size(18);
}

.titlePage--wordcount {
    margin-top: 1rem;
}

// Main
// ---------------------
.chapter {
    @extend %writeModeBox;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
}

.summary {
    @extend %writeModeBox;
    margin-bottom: 3rem;
    padding: 4px 1rem 2rem;

    .redactor-box {
        max-width: 1000px;
        margin-right: auto;
        margin-left: auto;
    }
}

.summary--heading {
    margin: 2rem 0 1rem;
    text-align: center;
}

// Modes
// ---------------------
.write_mode {
    .chapter {
        position: relative;
        margin-top: 1rem;
        padding: 3rem 1.5rem 4rem;

        @include bp(600) {
            padding: 2rem 0.75rem 4rem;
        }

        &:first-of-type {
            margin-top: 0;
        }
    }

    .chapter_title {
        margin-bottom: 4rem;
        padding: 6px 0;
        @include font-size(40);
        font-weight: 700;
        text-align: center;

        @include bp(600) {
            margin-bottom: 2rem;
            @include font-size(26);
        }
    }

    .chapter--add_scene {
        position: absolute;
        right: 4px;
        bottom: 4px;
        padding: 6px 14px;
        border-top: 1px solid;
        border-left: 1px solid;
        font-size: 14px;
    }

    .scene {
        max-width: 900px;
        margin-right: auto;
        margin-left: auto;
    }

    .scene_title {
        width: 100%;
        padding: 6px 10px;
        @include font-size(20);
        font-weight: 500;

        @include bp(600) {
            @include font-size(18);
        }
    }

    .scene_body {

        @include bp(600) {
            @include font-size(15);
        }

        .redactor-in {
            min-height: 360px;
            border-bottom: none;
        }

        .redactor-focus,
        .redactor-focus:focus {

            .redactor-in {
                border-bottom: none;
            }
        }

        .redactor-toolbar {
            border-top: none;
        }

        .redactor-toolbar a,
        .redactor-air a {
            padding: 8px 10px;
        }
    }

    .scene_footer {
        display: flex;
        justify-content: space-between;
        height: 32px;
    }

    .scene_footer--history {
        padding: 0.5rem 0;
        font-size: 14px;
    }

    .scene_footer--saved {
        padding: 0.5rem;
        font-size: 14px;
    }

    .scene_footer--delete {
        margin-left: 4px;
        padding: 0 4px;
        border-left: 1px solid;
        font-size: 12px;
    }

    .scene_footer--notes_button {
        padding: 0.5rem 0;
        font-size: 14px;
    }

    .scene_description {
        border: 1px solid;

        @include bp(600) {
            @include font-size(15);
        }

        .redactor-toolbar {
            border-top: none;
        }
    }

    .scene_divider {
        margin: 3rem 0;
        text-align: center;
    }

    .add_chapter {
        display: block;
        width: 90%;
        max-width: 320px;
        margin: 0 auto 8rem;
        padding: 6px;
        border: 1px solid;
    }
}

.outline_mode {
    .chapter_header {
        position: relative;
        padding: 0 40px 0 54px;
        border-bottom: 1px solid;

        @include bp(600) {
            padding: 0 30px 0 30px;
        }
    }

    .chapter_order {
        position: absolute;
        top: 4px;
        left: 0;
        width: 54px;
        border-right: 1px solid;
        line-height: 42px;
        text-align: center;

        @include bp(600) {
            width: 30px;
            line-height: 32px;

            span {
                display: none;
            }
        }
    }

    .chapter_delete {
        position: absolute;
        top: 4px;
        right: 0;
        width: 40px;
        border-left: 1px solid;
        line-height: 42px;

        @include bp(600) {
            width: 30px;
            font-size: 12px;
            line-height: 32px;
        }
    }

    .chapter_title {
        width: 100%;
        margin-bottom: 0;
        border: none;
        padding: 0 0.75rem;
        font-size: 18px;
        font-weight: 700;
        line-height: 50px;

        @include bp(600) {
            line-height: 40px;
        }
    }

    .chapter_description {
        margin: 0 0 2rem;
        padding: 1rem 3% 2rem;
        border-bottom: 1px solid;

        .redactor-box {
            max-width: 1000px;
            margin-right: auto;
            margin-left: auto;
        }
    }

    .chapter_description--label {
        max-width: 1000px;
        margin-right: auto;
        margin-left: auto;
        font-size: 12px;
        line-height: 30px;
    }

    .chapter--add_scene {
        width: 80%;
        max-width: 320px;
        margin: 0 auto 2rem;
        font-size: 14px;
    }

    .scenes {
        padding: 0 6%;

        @include bp(600) {
            padding: 0 6px;
        }
    }

    .scene {
        max-width: 1000px;
        margin: 0 auto 1.5rem;

        &:last-of-type {
            margin-bottom: 0.5rem;
        }
    }

    .scene_header {
        position: relative;
        padding: 0 40px 0 80px;
        border: 1px solid;

        @include bp(600) {
            padding: 0 32px 0 30px;
        }
    }

    .scene_order {
        position: absolute;
        top: 4px;
        left: 0;
        width: 80px;
        border-right: 1px solid;
        font-size: 14px;
        line-height: 28px;
        text-align: center;

        @include bp(600) {
            width: 30px;

            span {
                display: none;
            }
        }
    }

    .scene_delete {
        position: absolute;
        top: 4px;
        right: 0;
        width: 40px;
        border-left: 1px solid;
        line-height: 28px;

        @include bp(600) {
            width: 30px;
            font-size: 12px;
        }
    }

    .scene_title {
        width: 100%;
        margin-bottom: 0;
        padding: 0 0.75rem;
        border: none;
        font-size: 16px;
        font-weight: 700;
        line-height: 36px;
    }

    .scene_description {
        width: 94%;
        margin: 1rem auto 2rem;

        @include bp(600) {
            width: 100%;
        }
    }

    .scene_description--label {
        font-size: 12px;
        line-height: 30px;
    }

    .add_chapter {
        width: 90%;
        max-width: 480px;
        margin: 0 auto 8rem;
    }
}

.organize_mode {
    .chapter {
        position: relative;
        max-width: 1000px;
        margin-bottom: .5rem;
        padding-bottom: .2rem;
        padding-left: 2rem;

        &:last-of-type {
            margin-bottom: 4rem;
        }
    }

    .chapter-draggable-handle {
        cursor: move; /* fallback */
        cursor: grab;

        &:active {
            cursor: grabbing;
        }
    }

    .chapter-draggable-button {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 2rem;
        border-right: 1px solid;
        text-align: center;
        cursor: move; /* fallback */
        cursor: grab;
    }

    .chapter-draggable-icon {
        position: absolute;
        top: 50%;
        width: 100%;
        margin-top: -10px;
        line-height: 20px;
        cursor: move; /* fallback */
        cursor: grab;
    }

    .chapter-ghost {
        opacity: 0.5;
    }

    .chapter_title {
        margin: 0;
        padding: .5rem 30px .5rem 0.5rem;
        @include font-size(20);
        line-height: 2rem;

        span {
            display: inline-block;
            padding-right: 0.5rem;
            margin-right: 0.25rem;
            border-right: 1px solid;
            font-weight: normal;
        }

        @include bp(600) {
            padding-right: 1rem;
            padding-bottom: 40px;
        }
    }

    .chapter_buttons {
        position: absolute;
        top: 1rem;
        right: 1rem;

        @include bp(600) {
            top: 3.5rem;
        }
    }

    .chapter_button {
        display: inline-block;
        font-size: 14px;
        line-height: 1;

        &:focus {
            outline:none;
        }

        &:disabled {
            cursor: default;
        }
    }

    .chapter_scenes {
        padding: 0.5rem;
        border-top: 1px solid;
    }

    .scene {
        display: flex;
        margin-top: 0.25rem;
        border: 1px solid;

        &.disabled {
            cursor: default;

            .scene_title {
                cursor: default;
            }
        }
    }

    .scene-draggable-handle {
        cursor: move; /* fallback */
        cursor: grab;

        &:active {
            cursor: grabbing;
        }
    }

    .scene-draggable-button,
    .scene_title,
    .scene_buttons {
        line-height: 2.5rem;
    }

    .scene-draggable-button {
        padding: 0 0.25rem;
        border-right: 1px solid;
    }

    .scene-ghost {
        opacity: 0.5;
    }

    .scene_title {
        margin: 0;
        cursor: move; /* fallback */
        cursor: grab;

        span {
            margin-right: 0.25rem;
            padding: 0 0.5rem;
            border-right: 1px solid;
            font-weight: normal;
        }

        @include bp(720) {
            margin: 1rem 0;
            position: relative;
            padding-left: 48px;
            line-height: 1.3;

            span {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
        }
    }

    .scene_buttons {
        margin-left: auto;
        padding: 0 0.5rem;
        font-size: .75em !important;

        @include bp(1060) {
            position: static;
            margin-bottom: 1rem;
            text-align: center;
            transform: none;
        }
    }

    .scene_buttons--relocate {
        display: inline-block;
        width: auto;
        cursor: pointer;
        padding-top: .1rem;
        padding-bottom: .1rem;

        &:disabled {
            cursor: default;
        }
    }

    .scene-cant-relocate {
        margin-top: 0.5rem;
        font-size: 14px;
        text-align: right;
    }
}
