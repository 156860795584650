.form_block {
    @include clearfix;
    margin-bottom: 1.5em;

    &:last-of-type {
        margin-bottom: 0;
    }

    .btn {
        float: right;
        margin-left: 1rem;

        @include bp(600) {
            float: none;
            width: 100%;
            margin-bottom: 1rem;
            margin-left: 0;
        }
    }

    &.right {
        float: right;
        width: 48%;

        @include bp(700) {
            float: none;
            width: 100%;
        }
    }

    &.left {
        float: left;
        width: 48%;

        @include bp(700) {
            float: none;
            width: 100%;
        }
    }

    &.first {
        float: left;
        width: 32%;

        @include bp(700) {
            float: none;
            width: 100%;
        }
    }

    &.middle {
        float: left;
        width: 32%;
        margin: 0 2%;

        @include bp(700) {
            float: none;
            width: 100%;
            margin: 0 0 1.5rem;
        }
    }

    &.last {
        float: left;
        width: 32%;

        @include bp(700) {
            float: none;
            width: 100%;
        }
    }

    &.clear {
        clear: both;
    }

    &.highlight {
        display: none;
    }

    &.horizontal {
        @include clearfix;

        .form_label {
            float: left;
            width: 20%;
            margin-bottom: 0;
            text-align: right;
            line-height: 36px;
        }

        .form_control {
            float: right;
            width: 78%;
        }

        @include bp(700) {

            .form_label {
                float: none;
                width: 100%;
                margin-bottom: 0.75rem;
                text-align: left;
                line-height: 1;
            }

            .form_control {
                float: none;
                width: 100%;
            }
        }
    }

    &.one_line {
        display: flex;

        @include bp(700) {
            display: block;
        }
    }

    .one_line {

        &--label {
            flex-shrink: 0;
            flex-basis: 130px;
            margin-bottom: 0;
            line-height: 36px;

            .form_label {
                margin-bottom: 0;
            }

            @include bp(700) {
                margin-bottom: 0.75rem;
                line-height: 1;
            }
        }

        &--inputs {
            flex-grow: 1;

            .form_control {
                padding-top: 7px;
                padding-bottom: 7px;
            }

            &--two_columns {
                display: flex;

                @include bp(500) {
                    display: block;
                }

                .left {
                    width: 48%;

                    @include bp(500) {
                        width: 100%;
                        margin-bottom: 8px;
                    }
                }

                .right {
                    width: 48%;
                    margin-left: auto;

                    @include bp(500) {
                        width: 100%;
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

.form_question {
    @include clearfix;
    margin-bottom: 1.5em;
}

.form_answer {
    float: right;
    margin-left: 1rem;
    padding: 0.5rem;
    border: 1px solid;

    input {
        display: none;
    }
}

.form_label {
    display: block;
    margin-bottom: 0.75rem;
}

.form_control {
    display: block;
    width: 100%;
    padding: 0.5rem;
    border: 1px solid;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

    &.error {
        border-color: $red!important;
    }

    &:focus {
        outline: none;
    }

    &.small {
        padding: 0.25rem 0.5rem;
        line-height: 1;
    }
}

.form_help_text {
    margin-top: 4px;
    font-size: 0.875rem;
    text-align: right;
}

.form_toggle--question {
    float: left;
    line-height: 2rem;
}

.form_toggle--answers {
    float: right;
}

.form_toggle--input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
}

.form_toggle--label {
    float: right;
    display: inline-block;
    min-width: 60px;
    margin: 0.25rem 0;
    padding: 0 0.5rem;
    border: 1px solid;
    text-align: center;
    line-height: 1.5rem;
    transition: all 0.1s ease-in-out;

    &:hover {
        cursor: pointer;
    }

    &.right {
        border-radius: 0 4px 4px 0;
    }

    &.left {
        border-right: 0;
        border-radius: 4px 0 0 4px;
    }
}

.form_options {
    .form_options--value {
        width: 48%;
        margin-right: 2%;
    }

    &.two .form_options--value {
        width: 48%;
        margin-right: 2%;
    }

    &.three .form_options--value {
        width: 32%;
        margin-right: 1.3%;

        @include bp(800) {
            width: 48%;
            margin-right: 2%;
        }

        @include bp(500) {
            width: 100%;
            margin-right: 0;
        }
    }

    &.four .form_options--value {
        width: 24%;
        margin-right: 1%;

        @include bp(1300) {
            width: 32%;
            margin-right: 1.3%;
        }

        @include bp(800) {
            width: 48%;
            margin-right: 2%;
        }

        @include bp(500) {
            width: 100%;
            margin-right: 0;
        }
    }
}

.form_options--title {
    margin-bottom: 0.75rem;
}

.form_options--value {
    float: left;
    margin-bottom: 0.75rem;
}

.form_options--input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
}

.form_options--label {
    display: block;
    padding: 0.5rem;
    border: 1px solid;
    text-align: center;
    transition: all 0.1s ease-in-out;

    &:hover {
        cursor: pointer;
    }
}

.form_message {
    padding: 1rem;
    border: 1px solid;
}

.form_message--heading,
.form_message--text {
    text-align: center;
}

.form_message--heading {
    margin: 0 0 0.5rem;
}

.form_checkbox {
    margin: 0 1rem;

    &--input {
        margin-right: 8px;
    }

    &--link {
        color: $orange;
        text-decoration: none;
    }
}
