.create_section {
    display: flex;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid;

    @include bp(1100) {
        @include clearfix;
        display: block;
    }

    &--side_panel {
        flex-grow: 0;
        min-width: 280px;
        width: 280px;

        @include bp(1100) {
            width: 100%;
            max-width: 500px;
            margin-bottom: 2rem;
        }
    }

    &--heading {
        margin-top: 0;

        &--tag {
            position: relative;
            top: -2px;
            left: 4px;
            padding: 3px 10px;
            background-color: $orange;
            border-radius: 400px;
            font-size: 0.875rem;
            color: white;
        }
    }

    &--description {
        padding-right: 2rem;

        p {
            line-height: 1.35;
        }
    }

    &--main {
        flex-grow: 1;

        @include bp(1100) {
            padding-left: 2rem;
        }

        @include bp(500) {
            padding-left: 0;
        }
    }
}



.form_options--value.new_notebook {
    clear: both;
    margin-top: 10px;
}

.create_novel--new_notebook {
    float: left;
    margin-top: 10px;
}

.create_novel--new_notebook--label {
    margin: 0 10px;
    line-height: 34px;
}

.create_novel--new_notebook--input {
    padding: 0.5rem;
    line-height: 1rem;
    font-size: 1rem;
    border: 1px solid $grey2;
}
