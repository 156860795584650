.account--subheading {
    margin: 2rem 0 1.5rem;
    padding-top: 0.75rem;
    border-top: 1px solid;
}

h4.subscription_info--heading {
    margin: 0.25rem 0 0.75rem;
    font-size: 1.5rem;
    color: $red;
}

p.subscription_info--message {
    margin: 0.5rem 0;
    line-height: 1.5;
}

ul.subscription_info--list {
    margin: 0;

    li {
        line-height: 1.35;
    }
}

.subscription_info--buttons {
    display: flex;
    margin-bottom: 0.5rem;
}

p.subscription_info--price {
    margin: 0.5rem 0 1rem;
    font-size: 1.75rem;
    color: $orange;
    text-align: center;
    line-height: 2;
}

.subscription_info--trial {
    margin-bottom: 1.5rem;
    text-align: center;
    line-height: 1.5;
}

.subscription_info--item {
    font-size: 1.25rem;
    line-height: 1.5;

    span {
        float: right;
    }
}

.subscription_actions {
    margin: 1.5rem 0 -1.25rem;
    text-align: right;
}

.subscription_actions--button {
    margin-left: 24px;
}

.invoice--item {

    span {
        float: right;
    }
}

.update_form,
.upgrade_form {

    .form_block {
        &.card_number {
            float: left;
            width: 60%;
        }

        &.expiration {
            float: left;
            width: 22%;
            margin-left: 2%;

            .form_control {
                float: left;
                width: 46%;
                margin: 0 2%;
                text-align: center;
            }
        }

        &.cvc {
            float: left;
            width: 14%;
            margin-left: 2%;
        }
    }
}

p.stripe--error_message {
    margin-bottom: 2rem;
    padding: 10px;
    border: 1px solid;
    text-align: center;
}

.update_form--note {
    float: left;
    line-height: 38px;
}

.close_account--text {
    float: left;
    width: 70%;
    line-height: 1.5;

    @include bp(800) {
        float: none;
        width: 100%;
    }
}

.close_account--button {
    float: right;
    width: 25%;

    @include bp(800) {
        margin-top: 1rem;
        width: auto;
    }
}


.close_modal {
    padding: 0 2rem;
}

.close_modal--heading {
    margin: 0.5rem 0 1rem;
    @include font-size(24);
    text-align: center;
}

p.close_modal--subheading {
    margin-bottom: 2rem;
    @include font-size(18);
    text-align: center;
    line-height: 1.5;
}
