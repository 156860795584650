body {

    &#register {

        .siteHeader--nav--link.signUp {
            display: none;
        }
    }

    &.auth_body {
        background-color: $beige;

        .siteHeader--logo {

            @include bp(660) {
                text-align: center;
            }
        }

        .callToAction--button {
            display: none;
        }
    }
}

.auth--back_link {
    position: absolute;
    top: 1rem;
    left: 1rem;
    font-size: 14px;
    color: $orange;
    text-decoration: none;
}

.auth--logo {
    padding: 4rem 0 1rem;
    text-align: center;

    &--img {
        height: 40px;
    }
}

.auth {
    width: 90%;
    max-width: 440px;
    margin: 0 auto 4rem;
    padding: 2rem 0;

    &.wide {
        max-width: 600px;
    }

    .form_block {
        &.name_field {

            @include bp(700) {
                margin-bottom: 0;
            }
        }
    }

    .form_label {
        margin-bottom: 2px;
        color: $bluegrey5;
    }

    .form_control {
        padding: 10px 8px;
        border-color: $bluegrey2;
        font-size: 1.125rem;
    }
}

.auth_button {
    width: 100%;
    margin-top: 1rem;
    padding: 10px 8px;
    background-color: $orange;
    font-size: 1.25rem;
    color: $white;
}

.auth--title {
    margin-bottom: 3rem;
    font-size: 3.5rem;
    font-weight: 300;
    color: $bluegrey5;
    text-align: center;
}

.auth_box {
    padding: 1rem;
    background-color: $white;
}

.auth--terms {
    margin: 2rem 0 1rem;
    text-align: center;

    @include bp(700) {
        .form_checkbox {
            display: block;

            &:first-child {
                margin-bottom: 1rem;
            }
        }
    }
}

.auth--footer {
    margin-top: 3rem;
    text-align: center;
}

.auth--footer--text {
    color: $bluegrey4;

    a {
        @include link-colors( $orange, $orange1);
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.auth--heading {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid;
    font-size: 28px;
    text-align: center;
    line-height: 1.5;
}

.auth--forgot_password {
    @include link-colors($orange, $orange1);
    float: right;
    font-size: 14px;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.auth--password_toggle {
    padding: 0;
    background: transparent;
    color: $orange;

    &:focus,
    &:hover {
        outline: none;
        color: $orange3;
        text-decoration: underline;
    }
}

.auth--line {
    clear: both;
    margin: 20px 0;
    border: none;
    border-bottom: 1px solid $bluegrey2;
}

.auth_plans {
    display: flex;
    flex-wrap: wrap;

    &--button {
        flex-grow: 1;
        padding: 8px;
        background-color: transparent;
        border: 1px solid $grey3;
        color: $grey6;

        &.annual {
            margin-left: 1rem;
        }

        &:hover {
            border-color: $orange;
            color: $orange;
        }

        &.current {
            background-color: $orange;
            border-color: $orange;
            color: $white;
        }
    }
}

.auth--text {
    margin-bottom: 2rem!important;
    font-size: 1.25rem;
    line-height: 1.3;
}

.auth--note {
    clear: both;
    max-width: 400px;
    margin: 1rem auto 0;
    font-size: 0.875rem;
    text-align: center;
    line-height: 1.5;
    color: $grey5;
}

.auth--legal {
    margin-top: 1rem;
    font-size: 0.875rem;
    text-align: center;
    line-height: 1.5;

    a {
        @include link-colors($orange, $orange1);
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.auth_error {
    display: block;
    width: 100%;
    margin-top: 0.5rem;
    color: $red;
}

.auth--error_message {
    padding: 10px;
    border: 1px solid;
    text-align: center;
}

.error_box {
    margin-bottom: 1rem;
    padding: 10px 10px 1px;
    border: 1px solid $red;
}

.error_box--list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.error_box--item {
    margin-bottom: 9px;
    line-height: 1.5;
    color: $red;
}

.auth_message {
    margin-top: 20px;
    padding: 20px;
    background-color: $white;

    &--header {
        margin: 0 0 15px;
        color: $red;
        text-align: center;
    }

    &--text {
        text-align: center;
        line-height: 1.25;

        strong {
            display: block;
            margin-top: 10px;
            color: $red;
        }
    }
}
