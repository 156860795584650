.download_novel {
    @include clearfix;
    padding-bottom: 1rem;
    border-bottom: 1px solid;

    &:last-of-type {
        border-bottom: none;
    }
}

.download_novel--heading {
    @include font-size(24);
}

p.download_novel--text {
    margin: 0.5rem 0 1rem;
    line-height: 1.5;
}

.download_novel--settings {
    @include clearfix;
}