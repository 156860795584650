.dropzone {
  min-height: 0 !important; // 150px
  max-width: 200px;
  margin: 0.75rem auto;
  padding: 10px 20px !important; // 20px 20px
  //border: 2px solid rgba(0, 0, 0, 0.3);
  background: transparent !important; // white
}

.vue-dropzone {
  //border: 2px solid #e5e5e5;
  border-width: 1px !important;
  font-family: inherit !important; // Arial,sans-serif
  //letter-spacing: .2px;
  //color: #777;
  //transition: .2s linear;
}

.dropzone .dz-message {
  //text-align: center;
  margin: 0 !important; //2em 0
}

.dropzone-message {
  line-height: 1.3;
}

.imageUpload {
  margin: 0.75rem 0;
  text-align: center;
}

.imageUpload-inner {
  position: relative;
  display: inline-block;
  padding: 10px;
  border: 1px solid;
}

.imageUpload-image {
  display: block;
  max-height: 200px;
  margin: 0 auto 0.5rem;
}

.imageUpload-buttons {
  text-align: center;
}

.imageUpload-button {
  font-size: 12px;
}

.imageUpload-loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparentize(white, 0.6);
}

.imageUpload-loader-icon {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin-top: -20px;
  font-size: 40px;
  text-align: center;
}
