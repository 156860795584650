.faqs {
    padding: 0.5rem 0;
}

.faq {
    margin-bottom: 1.5rem;
    border: 1px solid;

    &:last-of-type {
        margin-bottom: 0;
    }
}

.faq--question {
    position: relative;
    width: 100%;
    padding: 1rem 3rem 1rem 1rem;
    @include font-size(18);
    text-align: left;

    &:focus {
        outline: none;
    }

    .icon_up,
    .icon_down {
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: translateY(-50%);
    }

    .icon_up {
        display: none;
    }

    .icon_down {
        display: block;
    }

    &.opened {

        .icon_up {
            display: block;
        }

        .icon_down {
            display: none;
        }
    }
}

.faq--answer {
    padding: 0.5rem 1rem;
    border-top: 1px solid;
    line-height: 1.5;
}

.contact_info--label {
    font-weight: 600;
}

.contact_info--item {
    margin: 4px 0 18px;
}